import React, { Component, useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import i18n from "../../utils/i18n";
import {
  Typography,
  Container,
  Button,
  TextField,
  Checkbox,
  Snackbar,
  CircularProgress,
  MenuItem,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiAlert from "@material-ui/lab/Alert";
import { Send, ChevronRight, MoreHoriz, Check } from "@material-ui/icons";
import { config } from "../../utils/constants";

import { Formik } from "formik";
import * as Yup from "yup";

import {
  getCountryByCountryCodeNew,
  getCountryObjByNameNew,
  getSortedCountriesNew,
  getCountryCodeShortNew,
} from "../../utils/countries";
import { CP_GATE } from "../../utils/constants";
import {
  generatePin,
  isEmailAvailable,
  verifyPin,
  registerIBUser,
  locateMe,
  getCountries,
} from "../../state/reducers/Auth";
import { withTranslation } from "react-i18next";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const validEmail = (email) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const phoneRegExp =
  /(\+|00)(297|93|244|1264|358|355|376|971|54|374|1684|1268|61|43|994|257|32|229|226|880|359|973|1242|387|590|375|501|1441|591|55|1246|673|975|267|236|1|61|41|56|86|225|237|243|242|682|57|269|238|506|53|5999|61|1345|357|420|49|253|1767|45|1809|1829|1849|213|593|20|291|212|34|372|251|358|679|500|33|298|691|241|44|995|44|233|350|224|590|220|245|240|30|1473|299|502|594|1671|592|852|504|385|509|36|62|44|91|246|353|98|964|354|972|39|1876|44|962|81|76|77|254|996|855|686|1869|82|383|965|856|961|231|218|1758|423|94|266|370|352|371|853|590|212|377|373|261|960|52|692|389|223|356|95|382|976|1670|258|222|1664|596|230|265|60|262|264|687|227|672|234|505|683|31|47|977|674|64|968|92|507|64|51|63|680|675|48|1787|1939|850|351|595|970|689|974|262|40|7|250|966|249|221|65|500|4779|677|232|503|378|252|508|381|211|239|597|421|386|46|268|1721|248|963|1649|235|228|66|992|690|993|670|676|1868|216|90|688|886|255|256|380|598|1|998|3906698|379|1784|58|1284|1340|84|678|681|685|967|27|260|263)(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{4,20}$/im;
const passwordRegExp =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z!@#$%^&*()_,.?":{}|<>\d]{8,20}$/;

let isPinVerified = false;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      className="p-3"
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const Form = (props) => {
  const {
    values: {
      type,
      firstName,
      lastName,
      countryResidency,
      city,
      phone,
      email,
      portalPassword,
      portalPasswordConfirm,
      emailPin,
      declarations,
      tos,
    },
    errors,
    touched,
    handleSubmit,
    handleChange,
    setFieldTouched,
    setFieldValue,
    activeSubmit,
    isLoading,
    language,
  } = props;
  const t = props.t;
  const [havePin, setHavePin] = useState(true);
  const [verifingPin, setVerifingPin] = useState(true);
  const [verifiedPin, setVerifiedPin] = useState(true);
  const [pinBtnText, setPinBtnText] = useState(t("Send PIN"));
  const [pinBtnIcon, setPinBtnIcon] = useState(<Send />);
  const [countries, setCountries] = React.useState([]);
  const myhandleChange = (e) => {
    setFieldTouched(e.target.name);
    handleChange(e);
  };
  const phoneRef = useRef();
  const pinRef = useRef();

  const sendPin = async (e) => {
    setPinBtnText(t("Sending"));
    setPinBtnIcon(<MoreHoriz />);
    try {
      // const lang = language === 'en-us' ? 'en-gb' : language;
      const lang = config.languages.find((v) => v.key == language).key1;
      const resp = await generatePin({ email: email, language: lang });
      if (resp.isSuccess) {
        setHavePin(true);
        setPinBtnText(t("Enter PIN"));
        setPinBtnIcon(<ChevronRight />);
      }
    } catch (error) {
      setHavePin(false);
      setPinBtnText(t("Send PIN"));
      setPinBtnIcon(<Send />);
    }
  };

  const verifyEmailPin = async () => {
    setVerifingPin(true);
    setPinBtnText(t("Verifying"));
    setPinBtnIcon(<MoreHoriz />);
    try {
      const resp = await verifyPin({ email: email, value: emailPin });
      if (resp.isSuccess) {
        setVerifingPin(false);
        setVerifiedPin(true);
        setPinBtnText(t("PIN Verified"));
        setPinBtnIcon(<Check />);
        isPinVerified = true;
      }
    } catch (error) {
      setVerifingPin(false);
      setPinBtnText(t("Enter PIN"));
      setPinBtnIcon(<ChevronRight />);
      errors.emailPin = t("PIN not verified");
      setFieldTouched(t("emailPin"));
    }
  };

  useEffect(() => {
    let locale;
    switch (language) {
      case "ar-ae":
        locale = "ar";
        break;
      case "ja-jp":
        locale = "jp";
        break;
      case "zh-cn":
        locale = "ch";
        break;
      case "es-es":
        locale = "es";
        break;
      case "so-kr":
        locale = "so";
        break;
      case "fa-ir":
        locale = "fa";
        break;
      case "ru":
        locale = "ru";
        break;
      case "en-us":
      default:
        locale = "en";
    }
    i18n.changeLanguage(locale);
    // i18n.changeLanguage(language === 'ar-ae' ? 'ar' : 'en')
  }, [language]);

  const tmp2 = async () => {
    try {
      const count = await getCountries();
      setCountries(getSortedCountriesNew(count));
    } catch (error) {
      console.log("testing Countries Error", error);
    }
  };

  useEffect(() => {
    tmp2();
  }, []);

  // useEffect(() => {
  //   setHavePin(false);
  //   setPinBtnText(t('Send PIN'));
  //   setPinBtnIcon(<Send />);
  // }, [email]);

  // useEffect(() => {
  //   if (havePin) pinRef.current.focus();
  // }, [havePin]);

  // useEffect(() => {
  //   if (emailPin.length === 6) verifyEmailPin();
  // }, [emailPin]);

  useEffect(() => {
    const selCountry = countries.find(
      (countries) => countries.countryEn === countryResidency
    );
    if (selCountry) {
      setFieldValue("phone", "+" + selCountry.calling_code);
    }
  }, [countryResidency]);

  const handleBlur = (field) => {
    setFieldTouched(field);
  };

  const [showPasswpord1, setShowPassword1] = useState(false);
  const [showPasswpord2, setShowPassword2] = useState(false);

  return (
    <Container className={language === "ar-ae" ? "dir-ar-ae" : ""}>
      <form onSubmit={handleSubmit}>
        {/* <TextField
          select
          variant="standard"
          className="mb-4"
          name="type"
          helperText={touched.type ? errors.type : ''}
          error={touched.type ? Boolean(errors.type) : false}
          label={t('Application Type')}
          value={type}
          onChange={myhandleChange}
          onBlur={() => {
            handleBlur('type');
          }}
          fullWidth
        >
          <MenuItem value="individual">{t('Individual')}</MenuItem>
          <MenuItem value="corporate">{t('Corporate')}</MenuItem>
        </TextField> */}
        <TextField
          variant="standard"
          className="mb-4"
          name="firstName"
          helperText={touched.firstName ? errors.firstName : ""}
          error={touched.firstName ? Boolean(errors.firstName) : false}
          label={type === "individual" ? t("First Name") : t("Company Name")}
          value={firstName}
          onChange={myhandleChange}
          onBlur={() => {
            handleBlur("firstName");
          }}
          fullWidth
        />
        {type === "individual" && (
          <TextField
            variant="standard"
            className="mb-4"
            name="lastName"
            helperText={touched.lastName ? errors.lastName : ""}
            error={touched.lastName ? Boolean(errors.lastName) : false}
            label={t("Last Name")}
            value={lastName}
            onChange={handleChange}
            // onBlur={handleChange}
            onBlur={() => {
              handleBlur("lastName");
            }}
            fullWidth
          />
        )}
        <Autocomplete
          id="country"
          className="mb-2"
          options={countries}
          fullWidth
          autoHighlight
          blurOnSelect
          value={getCountryObjByNameNew(countryResidency, countries)}
          onChange={(e, value) => {
            setFieldValue("countryResidency", value ? value.countryEn : "");
          }}
          getOptionLabel={(option) =>
            language === "ar-ae" ? option.countryAr : option.countryEn
          }
          renderOption={(option) => (
            <>{language === "ar-ae" ? option.countryAr : option.countryEn}</>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("Country of Residence")}
              name="countryResidency"
              value={countryResidency}
              helperText={
                touched.countryResidency ? errors.countryResidency : ""
              }
              error={
                touched.countryResidency
                  ? Boolean(errors.countryResidency)
                  : false
              }
              onBlur={() => {
                handleBlur("countryResidency");
              }}
              variant="standard"
            />
          )}
        />
        <TextField
          variant="standard"
          className="mb-4"
          name="phone"
          inputRef={phoneRef}
          onBlur={() => {
            handleBlur("phone");
          }}
          helperText={touched.phone ? errors.phone : ""}
          error={touched.phone ? Boolean(errors.phone) : false}
          label={t("Phone")}
          fullWidth
          value={phone}
          onChange={handleChange}
          inputProps={{ className: "dir-ltr" }}
        />
        <TextField
          variant="standard"
          className="mb-4"
          name="email"
          helperText={touched.email ? errors.email : ""}
          error={touched.email ? Boolean(errors.email) : false}
          label={t("Email")}
          onBlur={() => {
            handleBlur("email");
          }}
          fullWidth
          value={email}
          onChange={handleChange}
        />
        <TextField
          variant="standard"
          className="mb-2"
          name="portalPassword"
          onBlur={() => {
            handleBlur("portalPassword");
          }}
          helperText={touched.portalPassword ? errors.portalPassword : ""}
          error={
            touched.portalPassword ? Boolean(errors.portalPassword) : false
          }
          label={t("Password")}
          fullWidth
          type={showPasswpord1 ? "text" : "password"}
          value={portalPassword}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => {
                  setShowPassword1(!showPasswpord1);
                }}
              >
                {showPasswpord1 ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            ),
          }}
        />

        <TextField
          variant="standard"
          className="mb-2"
          name="portalPasswordConfirm"
          onBlur={() => {
            handleBlur("portalPasswordConfirm");
          }}
          helperText={
            touched.portalPasswordConfirm ? errors.portalPasswordConfirm : ""
          }
          error={
            touched.portalPasswordConfirm
              ? Boolean(errors.portalPasswordConfirm)
              : false
          }
          label={t("Confirm Password")}
          fullWidth
          type={showPasswpord2 ? "text" : "password"}
          value={portalPasswordConfirm}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => {
                  setShowPassword2(!showPasswpord2);
                }}
              >
                {showPasswpord2 ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            ),
          }}
        />
        <div className="mb-4">
          <div className="badge badge-pill badge-info mr-1">
            {t("8-20 characters")}
          </div>
          <div className="badge badge-pill badge-info mr-1">
            {t("Latin Letters")}
          </div>
          <div className="badge badge-pill badge-info mr-1">{t("Numbers")}</div>
        </div>
        {/* <div className="d-flex align-items-center justify-content-start">
          <Button
            className="btn-warning mb-4"
            disabled={Boolean(errors.email) || havePin}
            endIcon={pinBtnIcon}
            onClick={sendPin}>
            {t(pinBtnText)}
          </Button>
          <TextField
            variant="standard"
            className="mb-4 mx-3"
            inputRef={pinRef}
            label={t('Enter PIN')}
            helperText={touched.emailPin ? errors.emailPin : ''}
            error={touched.emailPin ? Boolean(errors.emailPin) : false}
            value={emailPin}
            onChange={handleChange}
            name="emailPin"
            disabled={!havePin || verifingPin || verifiedPin}
          />
        </div>
        {havePin && (
          <div className="MuiFormHelperText-root mb-4 text-center text-danger">
            {t('PIN sent to your email, please verify')}
          </div>
        )} */}
        <div className="d-flex justify-content-between">
          <Checkbox
            checked={tos}
            onChange={handleChange}
            name="tos"
            id="tos"
            value="true"
            className="align-self-start p-0 mx-2"
            color="default"
          />
          <div className="mb-4">
            <p>
              {t("By clicking here I give my consent for")}
              &nbsp; <b>{t("ROX Capitals")}</b> &nbsp;
              {t(
                "to contact me for marketing purposes, You can opt out at any time, For further details please see our"
              )}
              {t("Marketing and Communication Policy Statement")}.
            </p>
            <p
              className="MuiFormHelperText-root Mui-error"
              style={{ color: "red", fontSize: "small" }}
            >
              {touched.emailPin &&
                errors.tos &&
                t("You must tick the consent form")}
            </p>
          </div>
        </div>
        <div className="text-center">
          <Button
            style={{ minWidth: "8rem" }}
            type="submit"
            size="large"
            className="btn-success"
            disabled={!activeSubmit || props.isLoading}
          >
            {props.isLoading ? (
              <CircularProgress size={20} />
            ) : (
              t("Register Now")
            )}
          </Button>
        </div>
      </form>
    </Container>
  );
};

const LivePreviewExample = (props) => {
  const t = props.t;
  const validationSchema = Yup.object({
    type: Yup.string("Select a type").required(
      t("Application Type is required")
    ),
    firstName: Yup.string().when("type", {
      is: "individual",
      then: Yup.string("Enter first name").required(
        t("First name is required")
      ),
      otherwise: Yup.string("Enter company name").required(
        t("Company name is required")
      ),
    }),
    lastName: Yup.string().when("type", {
      is: "individual",
      then: Yup.string("Enter last name").required(t("Last name is required")),
    }),
    countryResidency: Yup.string("Select your country of residence")
      .typeError(t("Country of residence is required"))
      .required(t("Country of residence is required")),
    city: Yup.string("Enter City"),
    phone: Yup.string("Enter your Phone")
      .required(t("Phone is required"))
      .matches(phoneRegExp, t("Phone number is not valid")),
    email: Yup.string("Enter your email")
      .required(t("Email is required"))
      .email(t("Enter a valid email"))
      .test(
        "email-availability",
        t("Account already exists with this email, Kindly login"),
        async (value) => {
          try {
            if (validEmail(value)) {
              const resp = await isEmailAvailable({
                email: value.toLowerCase(),
              });

              return resp.result.availability;
            }
            return false;
          } catch (error) {
            console.error(error);
            return false;
          }
        }
      ),
    portalPassword: Yup.string("")
      .min(8, t("Password must contain atleast 8 characters"))
      .max(20, t("Password should not be more than 20 characters long"))
      .matches(
        passwordRegExp,
        t("Atleast one lower case, upper case and number required")
      )
      .required(t("Enter your password")),
    portalPasswordConfirm: Yup.string("")
      .oneOf([Yup.ref("portalPassword"), null], "Passwords must match")
      .required(t("Enter confirm password")),
    // emailPin: Yup.string('')
    //   .required(t('PIN must be entered'))
    //   .length(6, t('Pin should be 6 characters exact')),
    // .test('verify-pin', 'PIN must be verfied', async (value) => {
    //   return isPinVerified;
    // }),
    tos: Yup.boolean().oneOf(
      [true],
      t("Accept Terms & Conditions is required")
    ),
  });

  const { history, language } = props;

  const [activeSubmit, setActiveSubmit] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [infoBar, setInfoBar] = useState(false);
  const [errorBar, setErrorBar] = useState(false);
  const [partnerId, setPartnerId] = useState(false);
  const [ibId, setIbId] = useState(false);
  const [referral, setReferral] = useState(false);
  const [utmCampaign, setUtmCampaign] = useState(false);
  const [countries, setCountries] = React.useState([]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const partnerId = params.get("ibRef");
    const ibId = params.get("ibId");
    const referral = params.get("ref");
    const utm = params.get("utm-campaign");
    if (partnerId) {
      setPartnerId(partnerId);
      localStorage.setItem("partnerId", partnerId);
    }
    if (ibId) {
      setIbId(ibId);
      localStorage.setItem("ibId", ibId);
    }
    if (referral) {
      setReferral(referral);
      localStorage.setItem("referral", referral);
    }
    if (utm) {
      setUtmCampaign(utm);
      localStorage.setItem("utm", utm);
    }
  }, []);

  const submit = async (data) => {
    setActiveSubmit(false);
    setIsLoading(true);
    let storedPartner = localStorage.getItem("partnerId");
    let storedIbId = localStorage.getItem("ibId");

    if (partnerId) data.ibRef = partnerId;
    else if (storedPartner) data.ibRef = storedPartner;

    if (ibId) data.ibId = ibId;
    else if (storedIbId) data.ibId = storedIbId;

    try {
      let storedRef = localStorage.getItem("referral");
      if (referral) data.referral = referral;
      else if (storedRef) data.referral = storedRef;
      else data.referral = null;
      let storedUtm = localStorage.getItem("utm");
      if (utmCampaign) data.utmCampaign = utmCampaign;
      else if (storedUtm) data.utmCampaign = storedUtm;
      else data.utmCampaign = null;
      data.language = config.languages.find((v) => v.key === language).key1;
      data.countryCode = getCountryCodeShortNew(
        data.countryResidency,
        countries
      );
      const resp = await registerIBUser(data);

      setIsLoading(false);
      if (resp.isSuccess) {
        setInfoBar(true);
        localStorage.setItem("partnerId", null);
        localStorage.setItem("referral", null);
        window.location.replace(`${CP_GATE}?token=${resp.result}`);
      } else {
        setErrorBar(true);
        setActiveSubmit(true);
      }
    } catch (error) {
      setErrorBar(true);
      setActiveSubmit(true);
      setIsLoading(false);
    }
  };

  const initialValues = {
    type: "individual",
    firstName: "",
    lastName: "",
    countryResidency: "United Arab Emirates",
    phone: "+971",
    email: "",
    portalPassword: "",
    portalPasswordConfirm: "",
    emailPin: "",
    declarations: [
      "By clicking here I give my consent for ROX Capitals to contact me for marketing purposes. You can opt out at any time. For further details please see ourMarketing and Communication Policy Statement.",
    ],
    tos: false,
    language: language === "en-us" ? "en-gb" : language,
  };

  const handleInfoBarClose = () => {
    setInfoBar(false);
  };

  const handleErrorBarClose = () => {
    setErrorBar(false);
  };

  const [values, setValues] = useState(initialValues);

  const tmp = async () => {
    try {
      const currentCountry = await locateMe();
      setValues({
        ...values,
        countryResidency: getCountryByCountryCodeNew(
          currentCountry.country || "AE",
          countries
        ),
      });
    } catch (error) {
      console.log("Locate Error", error);
      setValues({
        ...values,
        countryResidency: getCountryByCountryCodeNew("AE", countries),
      });
    }
  };
  useEffect(() => {
    tmp();
  }, []);

  const tmp2 = async () => {
    try {
      const count = await getCountries();
      setCountries(getSortedCountriesNew(count));
    } catch (error) {
      console.log("testing Countries Error", error);
    }
  };

  useEffect(() => {
    tmp2();
  }, []);

  return (
    <>
      <Formik
        enableReinitialize={true}
        validateOnMount={false}
        validateOnChange={true}
        validate={false}
        validateOnBlur={true}
        initialValues={values}
        validationSchema={validationSchema}
        onSubmit={submit}
      >
        {(props) => (
          <Formis
            activeSubmit={activeSubmit}
            isLoading={isLoading}
            language={language}
            {...props}
          />
        )}
      </Formik>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // Auth: state.Auth
    language: state.language,
  };
};

// export default LivePreviewExample;
export default withTranslation()(
  connect(mapStateToProps, {})(LivePreviewExample)
);
const Formis = withTranslation()(Form);
